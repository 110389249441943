export const alphaNumeric = /^[a-zA-Z0-9]+$/;
export const acctNumFormat = /^[a-zA-Z0-9]{3}[0-9]{6}$/;


export function decodeJwtToken (token) {
    let response = null;
        try{
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        response  = JSON.parse(jsonPayload);
    }
    catch(exception)
    {
        //
    }
 return response;
}


export function getNestedObject(nestedObj, pathArr) {

    if(nestedObj !== null && nestedObj !== undefined 
        && pathArr !== null && pathArr !== undefined && Array.isArray(pathArr)){
        return pathArr.reduce((obj, key) =>
            (obj && obj[key] !== undefined && obj[key] !== null ) ? obj[key] : "", nestedObj);
    }
}

export function tabSelection(tabLinks, param) {

    if (!param.active) {

        for (const [, value] of tabLinks.entries()) {
            if (value.displayName === param.displayName) {
                value.active = true;
            } else {
                value.active = false;
            }
        }

    }
    return tabLinks;
}
export function tabSelectionOnLoad(tabsLinks, currentRoute, defaultRoute, parentRoute) {
    for (const [, value] of tabsLinks.entries()) {
        if ((value.tabUrl === defaultRoute && currentRoute === parentRoute) || value.tabUrl === currentRoute) {
            value.active = true;
        } else {
            value.active = false;
        }
    }
    return tabsLinks;
}
export const DateRanges = {

    PRIORMONTH: {
        label: 'Prior Month',
        value: 'PriorMonth'
    },
    PRIOR3MONTHS: {
        label: 'Prior 3 Months',
        value: 'Prior3Months'
    },
    PRIOR6MONTHS: {
        label: 'Prior 6 Months',
        value: 'Prior6Months'
    },
    PRIOR12MONTHS: {
        label: 'Prior 12 Months',
        value: 'Prior12Months'
    },
    CURRENTYEAR: {
        label: 'Current Year',
        value: 'CurrentYear'
    },
    PRIORYEAR: {
        label: 'Prior Year',
        value: 'PriorYear'
    },
    CUSTOMRANGE: {
        label: 'Custom Range',
        value: 'CustomRange'
    },
    YESTERDAY: {
        label: 'Yesterday',
        value: 'Yesterday'
    },
    LAST5DAYS: {
        label: 'Last 5 Days',
        value: 'Last5Days'
    },
    LAST7DAYS: {
        label: 'Last 7 Days',
        value: 'Last7Days'
    },
    LAST30DAYS: {
        label: 'Last 30 Days',
        value: 'Last30Days'
    },
    LAST90DAYS: {
        label: 'Last 90 Days',
        value: 'Last90Days'
    }

};

export const MessageType = {
    'SUCCESS': 'Success',
    'ERROR': 'Error',
    'INFO': 'Info',
    'WARNING': 'Warning',
    'LABEL': 'Label',
    'PREFERENCE_WARNING': 'PREFERENCE_WARNING'

}

export const MessageClass = {
    'SUCCESS': 'alert alert-success alert-dismissible fade show',
    'ERROR': 'RCM_validation_error_text',
    'INFO': 'alert alert-info alert-dismissible fade show',
    'WARNING': 'alert alert-warning alert-dismissible fade show',
    'LABEL': "",
    'PREFERENCE_WARNING': ''
}
