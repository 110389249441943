import {
  INSERT_FA_DETAILS,
  INSERT_TOKEN,
  INSERT_FA_POOLS_LIST,
  INSERT_ENV_VARIABLES,
  REFRESH,
  INSERT_FA_PREFERENCE,
  INSERT_ADVISOR_HANDLE,
  SET_SELECTED_ADVISORHANDLE
} from "./actionTypes";

export function ADD_FA_DETAILS(faDetails) {
  return {
    type: INSERT_FA_DETAILS,
    payload: faDetails,
  };
}

export const ADD_ENV_VARIABLES = (variables) => (dispatch) => {
  return Promise.resolve(
    dispatch({
      type: INSERT_ENV_VARIABLES,
      payload: variables,
    })
  );
};

export function ADD_FA_POOLS(faPoolList) {
  return {
    type: INSERT_FA_POOLS_LIST,
    payload: faPoolList,
  };
}

export function ADD_TOKEN(token) {
  return {
    type: INSERT_TOKEN,
    payload: token,
  };
}

export function REFRESH_APP(isRefresh) {
  return {
    type: REFRESH,
    payload: isRefresh,
  };
}

export function INSERT_FA_FEATURES(featureList) {
  return {
    type: INSERT_FA_PREFERENCE,
    payload: featureList,
  };
}

export function INSERT_ADVISOR_HANDLES(advisorsList) {
  return {
    type: INSERT_ADVISOR_HANDLE,
    payload: advisorsList,
  };
}

export function SET_SELECTED_ADVISOR_HANDLE(advisor) {
    return {
        type: SET_SELECTED_ADVISORHANDLE,
        payload: advisor
    }
}
