import axios from "axios";
import { Get, Post } from "../servicecalls";
import {
  apiBaseUrl,
  getProposalListUrl,
  getProposalDetailsUrl,
  saveProposalDetailUrl,
  createAndSendDocusignUrl,
  downloadDocumentsUrl,
  getAdvisorHandlesUrl
} from "../serviceConstant";

export var getProposalListCancellationToken = null;
export var getProposalDetailsCancellationToken = null;
export var saveProposalDetailsCancellationToken = null;
export var createAndSendDocusignCancellationToken = null;
export var downloadDocumentsCancellationToken = null;
export var getAdvisorsHandlesCancellationToken = null;
export var getProposalsListByAdvisorCancellationToken = null;

export const getListOfProposalsListService = () => {
  if (
    getProposalListCancellationToken !== null &&
    getProposalListCancellationToken !== undefined
  ) {
    getProposalListCancellationToken.cancel(
      "Cancel existing get goal list to prevent memory leak."
    );
  }

  //Create a new cancellation token
  const cancelToken = axios.CancelToken;
  getProposalListCancellationToken = cancelToken.source();
  const url = `${apiBaseUrl()}${getProposalListUrl}`;
  let getGoalListPromise = new Promise(function (resolve, reject) {
    Get(url, getProposalListCancellationToken)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          console.log(err);
        } else {
          console.log("Error Occured While fetching proposals", err);
          reject(err);
        }
      });
  });
  return getGoalListPromise;
};

export const getProposalsDetailsService = (request) => {
  if (
    getProposalDetailsCancellationToken !== null &&
    getProposalDetailsCancellationToken !== undefined
  ) {
    getProposalDetailsCancellationToken.cancel(
      "Cancel existing get proposal details service call to prevent memory leak"
    );
  }

  //Create a new cancellation token
  const cancelToken = axios.CancelToken;
  getProposalDetailsCancellationToken = cancelToken.source();
  const url = `${apiBaseUrl()}${getProposalDetailsUrl}`;
  let getProposalDetailPromise = new Promise(function (resolve, reject) {
    Post(url, request, getProposalDetailsCancellationToken)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          console.log(err);
        } else {
          console.log("Error Occured While fetching proposal details", err);
          reject(err);
        }
      });
  });
  return getProposalDetailPromise;
};

export const saveProposalDetailsService = (request) => {
  if (
    saveProposalDetailsCancellationToken !== null &&
    saveProposalDetailsCancellationToken !== undefined
  ) {
    saveProposalDetailsCancellationToken.cancel(
      "Cancel save proposal details service call to prevent memory leak"
    );
  }

  //Create a new cancellation token
  const cancelToken = axios.CancelToken;
  saveProposalDetailsCancellationToken = cancelToken.source();
  const url = `${apiBaseUrl()}${saveProposalDetailUrl}`;
  let saveProposalDetailPromise = new Promise(function (resolve, reject) {
    Post(url, request, saveProposalDetailsCancellationToken)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          console.log(err);
        } else {
          console.log("Error Occured While saving proposal details", err);
          reject(err);
        }
      });
  });
  return saveProposalDetailPromise;
};

export const createAndSendDocusignService = (proposalId) => {
  if (
    createAndSendDocusignCancellationToken !== null &&
    createAndSendDocusignCancellationToken !== undefined
  ) {
    createAndSendDocusignCancellationToken.cancel(
      "Cancel create and send docusign service call to prevent memory leak"
    );
  }

  //Create a new cancellation token
  const cancelToken = axios.CancelToken;
  createAndSendDocusignCancellationToken = cancelToken.source();
  const url = `${apiBaseUrl()}${createAndSendDocusignUrl}${proposalId}`;
  let createAndSendDocusignPromise = new Promise(function (resolve, reject) {
    Get(url, createAndSendDocusignCancellationToken)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          console.log(err);
        } else {
          console.log(
            "Error Occured While create and send docusugn envolve",
            err
          );
          reject(err);
        }
      });
  });
  return createAndSendDocusignPromise;
};


export const downloadDocumentsService = (req) => {
  if (
    downloadDocumentsCancellationToken !== null &&
    downloadDocumentsCancellationToken !== undefined
  ) {
    downloadDocumentsCancellationToken.cancel(
      "Cancel existing get goal list to prevent memory leak."
    );
  }

  //Create a new cancellation token
  const cancelToken = axios.CancelToken;
  downloadDocumentsCancellationToken = cancelToken.source();
  const url = `${apiBaseUrl()}${downloadDocumentsUrl}`;
  let downloadDocumentsPromise = new Promise(function (resolve, reject) {
    Post(url,req, downloadDocumentsCancellationToken)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          console.log(err);
        } else {
          console.log("Error Occured While fetching documents", err);
          reject(err);
        }
      });
  });
  return downloadDocumentsPromise;
};

export const getAdvisorsHandlesService = () => {
  if (
    getAdvisorsHandlesCancellationToken !== null &&
    getAdvisorsHandlesCancellationToken !== undefined
  ) {
    getAdvisorsHandlesCancellationToken.cancel(
      "Cancel existing get advisors handles list to prevent memory leak."
    );
  }

  //Create a new cancellation token
  const cancelToken = axios.CancelToken;
  getAdvisorsHandlesCancellationToken = cancelToken.source();
  const url = `${apiBaseUrl()}${getAdvisorHandlesUrl}`;
  let advisorsHandlePromise = new Promise(function (resolve, reject) {
    const req = {}
    Post(url,req, getAdvisorsHandlesCancellationToken)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          console.log(err);
        } else {
          console.log("Error Occured While fetching advisors handles", err);
          reject(err);
        }
      });
  });
  return advisorsHandlePromise;
};


export const getProposalsListByAdvisorHandleService = (req) => {
  if (
    getProposalsListByAdvisorCancellationToken !== null &&
    getProposalsListByAdvisorCancellationToken !== undefined
  ) {
    getProposalsListByAdvisorCancellationToken.cancel(
      "Cancel existing get proposal list by advisor handle to prevent memory leak."
    );
  }

  //Create a new cancellation token
  const cancelToken = axios.CancelToken;
  getProposalsListByAdvisorCancellationToken = cancelToken.source();
  const url = `${apiBaseUrl()}${getProposalListUrl}`;
  let proposalListPromise = new Promise(function (resolve, reject) {
    Post(url,req, getProposalsListByAdvisorCancellationToken)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          console.log(err);
        } else {
          console.log("Error Occured While fetching proposal list by advisors handle", err);
          reject(err);
        }
      });
  });
  return proposalListPromise;
}