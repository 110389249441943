import React from 'react';
import { authProvider } from "../App";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export const withSession  = (OriginalComponent) => {
    class WrappedComponent extends React.Component {
      constructor(props) {
        super(props);
        this.state = {
          warningTime: 1000 * 60 * 29,
          signoutTime: 1000 * 60 * 30,
          modalState: false,
          //timeoutValue: 60
        };

        this.toggleModal = this.toggleModal.bind(this);
      }
  
      componentDidMount() {
        this.events = [
          'load',
          'mousemove',
          'mousedown',
          'click',
          'scroll',
          'keypress'
        ];
  
        for (var i in this.events) {
          window.addEventListener(this.events[i], this.resetTimeout);
        }
  
        this.setTimeout();
      }
  
      clearTimeoutFunc = () => {
        if (this.warnTimeout) clearTimeout(this.warnTimeout);
        if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
      };
  
      setTimeout = () => {
        this.warnTimeout = setTimeout(this.warn, this.state.warningTime);
        this.logoutTimeout = setTimeout(this.logout, this.state.signoutTime);
      };
  
      resetTimeout = () => {
        this.clearTimeoutFunc();
        this.setTimeout();
      };
  
      warn = () => {
        this.setState({
            modalState: true
        })

        // setInterval(() => {
        //     this.setState(prevState => ({
        //         timeoutValue: prevState.timeoutValue - 1
        //     }));
        // }, 1000)

      };

      toggleModal(){
        this.setState(prevState => ({
            modalState: !prevState.modalState
        }))
      }
  
      logout = () => {
        // Send a logout request to the API
        sessionStorage.clear();
        authProvider.logout();
      };

  
      render() {
  
        return (
            <React.Fragment>
                <OriginalComponent {...this.props} />
                <Modal
                    isOpen={this.state.modalState}
                    toggle={this.state.toggleModal}
                    zIndex='9999'
                    className="RCM_CM_modal"
                    wrapClassName="RCM_CM_modal_wrapper"
                    modalClassName="RCM_CM_modal_class"
                    contentClassName="RCM_CM_modal_content"
                    centered>
                    <ModalHeader toggle={this.props.toggleModal} charCode="X" ></ModalHeader>
                    <ModalBody>
                        <div align="center"><b>Session is about to expire in {this.state.timeoutValue} seconds, inactivity will logout user.</b></div>
                    </ModalBody>
                    <ModalFooter>

                    </ModalFooter>
                </Modal>
            </React.Fragment>
            
        );
      }
    }

    return WrappedComponent;
  }