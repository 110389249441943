import {
  INSERT_FA_DETAILS,
  INSERT_TOKEN,
  INSERT_FA_POOLS_LIST,
  INSERT_ENV_VARIABLES,
  REFRESH,
  INSERT_FA_PREFERENCE,
  INSERT_ADVISOR_HANDLE,
  SET_SELECTED_ADVISORHANDLE
} from "../actions/actionTypes";

const initialState = {
  faDetails: {},
  faPoolList: [],
  faFeatures: [],
  token: null,
  env: [],
  refresh: false,
};

export function AppReducer(state = initialState, action) {
  switch (action.type) {
    case INSERT_FA_DETAILS: {
      return {
        ...state,
        faDetails: action.payload,
      };
    }

    case INSERT_FA_POOLS_LIST: {
      return {
        ...state,
        faPoolList: action.payload,
      };
    }

    case INSERT_TOKEN: {
      return {
        ...state,
        token: action.payload,
      };
    }

    case INSERT_ENV_VARIABLES: {
      return {
        ...state,
        env: action.payload,
      };
    }
    case REFRESH: {
      return {
        ...state,
        refresh: action.payload,
      };
    }

    case INSERT_FA_PREFERENCE: {
      return {
        ...state,
        faFeatures: action.payload,
      };
    }

    case INSERT_ADVISOR_HANDLE: {
      return {
        ...state,
        advisorsHandle: action.payload,
      };
    }

    case SET_SELECTED_ADVISORHANDLE: {
      return {
        ...state,
        selectedAdvisorHandle: action.payload
      }
    }
    default:
      return state;
  }
}
