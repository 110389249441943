import { ApplicationInsights } from  '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from "history";
import { store } from  '..'

export class TelemetryService {
    constructor() {
        let _instrumentationKey = store.getState().app.env ? store.getState().app.env.APPINSIGHTS_INSTRUMENTATIONKEY: null;
         if(_instrumentationKey){
           this.instrumentationKey = _instrumentationKey;
         }
        const browserHistory = createBrowserHistory({ basename: '' });
        this.reactPlugin = new ReactPlugin();
        this.appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: this.instrumentationKey,
                disableFetchTracking: true,
                disableAjaxTracking: true,
                isStorageUseDisabled: true,
                maxBatchSizeInBytes: 10000,
                maxBatchInterval: 15000,
            },
            extensions: [this.reactPlugin],
            extensionConfig: {
                [this.reactPlugin.identifier]: { history: browserHistory }
            }
        });
        this.appInsights.loadAppInsights();
       
    }
}