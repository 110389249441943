import React, { Suspense, lazy } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Route, Switch } from "react-router";
import {
  ADD_FA_DETAILS,
  ADD_TOKEN,
  ADD_FA_POOLS,
  ADD_ENV_VARIABLES,
  INSERT_FA_FEATURES,
  INSERT_ADVISOR_HANDLES,
  SET_SELECTED_ADVISOR_HANDLE
} from "../src/actions/appActions";
import { getfaDetails, getfaFeatures } from "./services/appServices/appService";
import { getAdvisorsHandlesService } from './services/goalModificationServices/goalModificationService'
import { ConnectedRouter } from "react-router-redux";
import "antd/dist/antd.css";
import "./App.css";
import { withSession } from "./hoc/withSession";
import ErrorBoundary from "./components/error/errorBoundary";
import { _logInformation, _logException } from "./common/_logging";
import AppFooter from "./common/appFooter";
import MsalAuthProvider from "./system/MsalUtilities";
import { getNestedObject } from "./common/common";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { Spinner } from "reactstrap";
const Layout = lazy(() => import("./components/Layout"));
const Home = lazy(() => import("./components/Home"));
const Documents = lazy(() => import("./components/documents/Documents"));
const uploadDocuments = lazy(() =>
  import("./components/accountupload/accountUploadDocuments")
);
const bulkAccountUpload = lazy(() =>
  import("./components/bulkaccountupload/bulkAccountUpload")
);
const referenceDocument = lazy(() =>
  import("./components/referencedocuments/referenceDocuments")
);
const reports = lazy(() =>
  import("./components/reports/AccountReportsLanding")
);
const acctclone = lazy(() => import("./components/acctclone/acctclone"));
const notfound = lazy(() => import("./common/notfound"));
const goalModification = lazy(() =>
  import("./components/goalModification/GoalModificationContainer")
);

export var authProvider = null;
class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loginSuccess: false,
      showAuthError: false,
      showLogOutOnError: false,
      errorCode: "",
      errorMessage: "",
    };
    this.loginUser = this.loginUser.bind(this);
    this.fetchFinancialAdvisorDetails =
      this.fetchFinancialAdvisorDetails.bind(this);
  }

  componentDidMount() {
    this.props.ADD_ENV_VARIABLES(this.props.env).then(() => {
      _logInformation(this.props.env);
      this.loginUser();
    });
  }

  //create a function to login FA's
  loginUser(env) {
    //create auth Provider instance
    const msalAuthProvider = new MsalAuthProvider();
    authProvider = msalAuthProvider.getMsalInstance(env);
    authProvider
      .handleRedirectPromise()
      .then(() => {
        const accounts = msalAuthProvider.getAllAccounts();
        if (accounts && Array.isArray(accounts) && accounts.length > 0) {
          _logInformation(
            "Handle Redirect Promise Returned data and accounts present"
          );
          msalAuthProvider
            .getTokenSilent(accounts[0])
            .then((authResponse) => {
              const token = getNestedObject(authResponse, ["accessToken"]);
              const faEmail = getNestedObject(authResponse, [
                "idTokenClaims",
                "preferred_username",
              ]);
              sessionStorage.setItem("faEmail", faEmail);
              this.processAfterLogin(token, faEmail);
            })
            .catch((error) => {
              //Check if error is interaction type
              if (error instanceof InteractionRequiredAuthError) {
                // fallback to interaction when silent call fails
                msalAuthProvider.interactionBasedLogin();
              }
            });
        } else {
          console.log("No account found, need interaction based login");
          _logInformation("No account found, need interaction based login");
          msalAuthProvider.interactionBasedLogin();
        }
      })
      .catch((err) => {
        console.log("Exception::", err);
        _logException(err);
        this.setState({
          showAuthError: true,
          showLogOutOnError: false,
          errorCode: err.errorCode,
          errorMessage: err.errorMessage,
        });
      });
  }

  processAfterLogin(token, faEmail) {
    this.props.ADD_TOKEN(token);

    //check url 
    if(window.location.pathname.toLowerCase().startsWith("/accountservicing")){
      this.fetchAccountServicingAdvisors();
    } else {
      this.fetchFaInfo(faEmail);
    }
  }

  //fetch financial advisor's details
  fetchFinancialAdvisorDetails(faEmail) {
    getfaDetails(faEmail)
      .then((res) => {
        let faDetils = {
          advisorEmail: res.data[0].advisorEmail,
          advisorId: res.data[0].advisorId,
          advisorName: res.data[0].advisorName,
          advisorNumber: res.data[0].advisorNumber,
          advisorPool: res.data[0].advisorPool,
          advisorTeam: res.data[0].advisorTeam,
          advisorFaEmail: res.data[0].advisorFaEmail,
        };
        this.props.ADD_FA_DETAILS(faDetils);
        this.props.ADD_FA_POOLS(res.data);
        this.setState({
          loginSuccess: true,
        });
      })
      .catch((err) => {
        console.log("Error in get fa details api", err);
        _logException(`Unable to find financial advisor`);
        this.setState({
          loginSuccess: false,
          showAuthError: true,
          showLogOutOnError: true,
        });
      });
  }

  fetchFaInfo = (emailId) => {
    getfaFeatures(emailId)
      .then((res) => {
        let featuredata = res && res.data ? res.data : [];
        this.props.INSERT_FA_FEATURES(featuredata);
        this.fetchFinancialAdvisorDetails(emailId);
      })
      .catch(() => {
        this.fetchFinancialAdvisorDetails(emailId);
      });
  };


  fetchAccountServicingAdvisors = () => {
    getAdvisorsHandlesService()
    .then((res) => {
      if(res && res.data && res.data.status && res.data.status.Code === "0" 
          && res.data.advisors && Array.isArray(res.data.advisors) && res.data.advisors.length > 0){
        const advisorsWithFUllName = res.data.advisors.map(x => ({...x, FullName: `${x.FirstName} ${x.MiddleName} ${x.LastName}`}))
        this.props.INSERT_ADVISOR_HANDLES(advisorsWithFUllName)
        this.props.SET_SELECTED_ADVISOR_HANDLE(advisorsWithFUllName[0])
        this.setState({
          loginSuccess: true,
        });
      } else {
        console.error("Advisors api returned non zero code or no advisor list present", res)
        _logException("Advisors handle list api returned non zero code")
        this.setState({
          loginSuccess: false,
          showAuthError: true,
          showLogOutOnError: true,
        });
      }
    })
    .catch(err => {
       console.error("Error while fetching advisors handles", err)
       _logException("Unable to fetch advisors handles")
       this.setState({
        loginSuccess: false,
        showAuthError: true,
        showLogOutOnError: true,
      });
    })
  }

  logout() {
    if (authProvider) authProvider.logout();
  }

  render() {
    return this.state.loginSuccess ? (
      <ErrorBoundary>
        <div style={{ paddingBottom: "3.7rem" }}>
          <ConnectedRouter history={this.props.history}>
            <Suspense
              fallback={
                <div align="center">Loading Account Opening System...</div>
              }
            >
              <Layout>
                <Switch>
                  <Route exact path="/" component={Home} />
                  <Route path="/documents/:id?" component={Documents} />
                  <Route path="/bulkUpload" component={bulkAccountUpload} />
                  <Route
                    path="/uploadDocuments/:account?/:indClone?"
                    component={uploadDocuments}
                  />
                  <Route
                    path="/referenceDocument"
                    component={referenceDocument}
                  />
                  <Route path="/reports" component={reports} />
                  <Route path="/accountsummary" component={reports} />
                  <Route path="/accountstatus" component={reports} />
                  <Route path="/acctclone/:toacct?" component={acctclone} />
                  <Route
                    path="/accountservicing"
                    component={goalModification}
                    exact
                  />
                  <Route path="*" component={notfound} />
                </Switch>
              </Layout>
            </Suspense>
          </ConnectedRouter>
        </div>
        <AppFooter />
      </ErrorBoundary>
    ) : this.state.showAuthError ? (
      <div align="center">
        You are not authorized to access, please contact support.
        {this.state.showLogOutOnError && (
          <input
            type="button"
            onClick={this.logout}
            value="Log Out"
            className="btn btn-link RCM_Table_link"
          />
        )}
        <div style={{ color: "red" }}>
          {this.state.errorCode} - {this.state.errorMessage}
        </div>
      </div>
    ) : (
      <div align="center">
        <div id="RCM_Loading_Logo" className="RCM_Loading_Logo">
          <span>
            R
            <Spinner
              animation="grow"
              size="sm"
              variant="info"
              className="RCM_Loading_Logo_Spinner"
            ></Spinner>
          </span>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {};
};

const mapDispatchToProps = {
  ADD_FA_DETAILS,
  ADD_FA_POOLS,
  ADD_TOKEN,
  ADD_ENV_VARIABLES,
  INSERT_FA_FEATURES,
  INSERT_ADVISOR_HANDLES,
  SET_SELECTED_ADVISOR_HANDLE
};

const enhancement = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withSession
);

export default enhancement(App);
