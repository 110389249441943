import axios from "axios";
import { getFinancialAdvisorDetailsUrl, getFinancialAdvisorsAccountUrl, getEnvironmentVariableUrl, financialAdvisorsFeatureUrl } from "../serviceConstant";
import { Get } from "../servicecalls";

export var getfaDetailsCancellationToken = null;
export var getfaAccountsCancellationToken = null;
export var getEnvironmentVariablesCancellationToken = null;
export var getfaFeaturesCancellationToken = null;

export const getEnvironmentVariables = () => {
    

    if (getEnvironmentVariablesCancellationToken !== null && getEnvironmentVariablesCancellationToken !== undefined) {
        getEnvironmentVariablesCancellationToken.cancel("Cancel existing get environment variables to prevent memory leak.");
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    getEnvironmentVariablesCancellationToken = cancelToken.source();

    let getEnvironmentVariablePromise = new Promise(function (resolve, reject) {
        Get(getEnvironmentVariableUrl, getEnvironmentVariablesCancellationToken).then(
            environmentRes => {
                resolve(environmentRes);
            }
        ).catch(err => {
            if (axios.isCancel(err)) {
                console.log(err);
            } else {
                console.log("Error Occured While Fetching environment data", err);
                reject(err);
            }
        })
    });


    return getEnvironmentVariablePromise;


}

export const getfaDetails  = (emailId) => {

    if (getfaDetailsCancellationToken !== null && getfaDetailsCancellationToken !== undefined) {
        getfaDetailsCancellationToken.cancel("Cancel existing get fa details call to prevent memory leak.");
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    getfaDetailsCancellationToken = cancelToken.source();

    let getfaDetailsPromise = new Promise(function (resolve, reject) {
        const faDetailsUrl = `${getFinancialAdvisorDetailsUrl}?emailId=${emailId}`;
        Get(faDetailsUrl, getfaDetailsCancellationToken).then(
            faResponse => {
                resolve(faResponse);
            }
        ).catch(err => {
            if (axios.isCancel(err)) {
                console.log(err);
            } else {
                console.log("Error Occured While Fetching fa data", err);
                reject(err);
            }
        })
    });


    return getfaDetailsPromise;

}

export const getfaFeatures  = (emailId) => {

    if (getfaFeaturesCancellationToken !== null && getfaFeaturesCancellationToken !== undefined) {
        getfaFeaturesCancellationToken.cancel("Cancel existing get fa featurescall to prevent memory leak.");
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    getfaFeaturesCancellationToken = cancelToken.source();

    let getfaFeaturesPromise = new Promise(function (resolve, reject) {
        const faFeaturesUrl = `${financialAdvisorsFeatureUrl}?emailId=${emailId}`;
        Get(faFeaturesUrl, getfaFeaturesCancellationToken).then(
            faResponse => {
                resolve(faResponse);
            }
        ).catch(err => {
            if (axios.isCancel(err)) {
                console.log(err);
            } else {
                console.log("Error Occured While Fetching fa features", err);
                reject(err);
            }
        })
    });


    return getfaFeaturesPromise;

}



export const getfaAccounts  = (advisorId) => {

    if (getfaAccountsCancellationToken !== null && getfaAccountsCancellationToken !== undefined) {
        getfaAccountsCancellationToken.cancel("Cancel existing get fa account call to prevent memory leak.");
    }

    //Create a new cancellation token
    const cancelToken = axios.CancelToken;
    getfaAccountsCancellationToken = cancelToken.source();

    let getfaAccountsPromise = new Promise(function (resolve, reject) {
        const faAccountsUrl = `${getFinancialAdvisorsAccountUrl}?id=${advisorId}`;
        Get(faAccountsUrl, getfaAccountsCancellationToken).then(
            faAccounts => {
                resolve(faAccounts);
            }
        ).catch(err => {
            if (axios.isCancel(err)) {
                console.log(err);
            } else {
                console.log("Error Occured While Fetching fa data", err);
                reject(err);
            }
        })
    });


    return getfaAccountsPromise;

}