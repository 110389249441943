import {TelemetryService} from  "./telemetaryService";
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { store } from '..';


//var ai = null;

export function _logException(errorMessage)
{
    try {
        let ai =  new TelemetryService();
        ai.appInsights.clearAuthenticatedUserContext();
        let telemetryInitializer = getTelemetryInitializer();
        if(telemetryInitializer !== null){
            ai.appInsights.addTelemetryInitializer(getTelemetryInitializer());
        }   
        ai.appInsights.trackException({ error: new Error(`${JSON.stringify(errorMessage)}`), severityLevel: SeverityLevel.Error });
    } catch (err) {
        
    }
}

export function _logInformation(infoMessage)
{
    try{
        let ai = new TelemetryService();
        ai.appInsights.clearAuthenticatedUserContext();
        
        let telemetryInitializer = getTelemetryInitializer();
        if(telemetryInitializer !== null){
            ai.appInsights.addTelemetryInitializer(getTelemetryInitializer());
        }

        ai.appInsights.trackTrace({ message: `${JSON.stringify(infoMessage)}`, severityLevel: SeverityLevel.Information });
    }catch(err)
    {
        console.error(err);
    }
}

export function _logEvent(eventName)
{
    try{
        
        let ai = new TelemetryService();
        ai.appInsights.clearAuthenticatedUserContext();
        
        let telemetryInitializer = getTelemetryInitializer();
        if(telemetryInitializer !== null){
            ai.appInsights.addTelemetryInitializer(getTelemetryInitializer());
        }

        ai.appInsights.trackEvent({ name: eventName });
    }catch(err){

    }
}

export function _logPageView(pageView){

    try{
        let ai = new TelemetryService();
        ai.appInsights.clearAuthenticatedUserContext();
        
        let telemetryInitializer = getTelemetryInitializer();
        if(telemetryInitializer !== null){
            ai.appInsights.addTelemetryInitializer(getTelemetryInitializer());
        }

        ai.appInsights.trackPageView(pageView);
    }catch(err)
    {

    }
}

function getTelemetryInitializer()
{
    let telemetryInitializer =null;
    let user = store.getState().app.faDetails;
     if(user)
     {

        telemetryInitializer = (envelope) => {
            envelope.tags['ai.user.id'] =  sessionStorage.getItem('faEmail')  || 'Not Avaliable';
            envelope.tags['ai.user.authUserId'] = sessionStorage.getItem('faEmail') || 'Not Avaliable';
        };
     }

    return telemetryInitializer;
}

